import APP from '../base'
import { CLazyHandler } from '../handlers'
import L from 'leaflet/dist/leaflet'
import 'leaflet/dist/leaflet.css'
import Sizes from './sizes'

export default class Map {
  constructor() {
    this.markers = []
    this.instance = null
    this.container = document.querySelector('#map-component')
    this.component = this.container.querySelector('.map')
    this.box = this.container.querySelector('.box')
    this.boxText = this.box.querySelector('.text')
    this.data = JSON.parse(document.querySelector('#map-data').textContent)
    this.active = null

    if (this.container && this.data) {
      new CLazyHandler(this.container, () => this.init(), { threshold: 0.2 })
    }
  }

  init() {
    this.instance = L.map(this.component, {
      maxZoom: 16,
      minZoom: 12,
      scrollWheelZoom: false,
      // dragging: false,
      // keyboard: false,
      // doubleClickZoom: false,
      zoomControl: false,
      // attribution: false,
      paddingTopLeft: [0, 400],
    })

    const tiles = L.tileLayer(
      'https://tiles.stadiamaps.com/tiles/stamen_toner_lite/{z}/{x}/{y}{r}.png',
      {
        maxZoom: 20,
        attribution:
          '&copy; <a href="https://stadiamaps.com/" target="_blank">Stadia Maps</a> &copy; <a href="https://stamen.com/" target="_blank">Stamen Design</a> &copy; <a href="https://openmaptiles.org/" target="_blank">OpenMapTiles</a> &copy; <a href="https://www.openstreetmap.org/about" target="_blank">OpenStreetMap</a> contributors',
        detectRetina: true,
      }
    ).addTo(this.instance)

    const bounds = L.latLngBounds(
      this.data.map((e) => [e.latitude, e.longitude])
    ).pad(0.02)

    const padding = Sizes.isMobile
      ? {
          paddingBottomRight: [0, (this.container.offsetHeight - 40) / 2],
        }
      : {
          paddingTopLeft: [
            Sizes.isTablet
              ? (this.container.offsetWidth - 40) / 2
              : (this.container.offsetWidth - 220) / 3,
            0,
          ],
        }

    this.instance.fitBounds(bounds, padding)

    this.markers = []

    this.data.forEach((e) => {
      const marker = L.marker([e.latitude, e.longitude], {
        icon: this.getMarkerIcon(),
      }).on('click', () => {
        this.activate(marker, e)
      })

      this.instance.addLayer(marker)
      this.markers.push(marker)
    })

    this.activate(
      this.markers[this.markers.length - 1],
      this.data[this.data.length - 1]
    )
  }

  loadInfo(data) {
    this.boxText.innerHTML = `
      <p class="is-size-3 is-size-2-desktop mb-4">
        <b>${data.name}</b>
      </p>
      <div class="is-size-5 has-text-weight-medium">${data.short_description}</div>
    `

    this.box.setAttribute('href', data.link)
  }

  activate(marker, data) {
    if (this.active) this.active.classList.remove('is-active')
    this.active = marker._icon
    this.active.classList.add('is-active')

    this.loadInfo(data)
  }

  getMarkerIcon() {
    const color = '#FF0000'

    return L.divIcon({
      html: `
      <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
        <circle cx="15" cy="15" r="12.5" stroke="currentColor" stroke-width="5"/>
      </svg>
      `,
      iconSize: [30, 30],
      iconAnchor: [15, 15],
    })
  }
}
