import Map from './components/map'
import Carousel from './components/carousel'

import '../scss/pages/home.scss'

class CHome {
  constructor() {
    document.addEventListener('DOMContentLoaded', () => {
      this.headerImage = document.querySelector('header.header-main .image')
      this.logo = document.querySelector(
        'header.header-main .navbar .navbar-brand .navbar-item'
      )

      this.initFeaturedPages()
      this.map = new Map()
    })
  }

  async initFeaturedPages() {
    this.active = null
    const box = document.querySelector('#featured-pages .box')
    const stamp = document.querySelector('#grid .is-stamp')

    const computeStampHeight = () =>
      (stamp.style.height = box.offsetHeight / 2 + 'px')

    this.carousel = new Carousel('#featured-pages .carousel', {
      wrapAround: true,
      prevNextButtons: false,
      autoPlay: 4000,
      pauseAutoPlayOnHover: false,
      on: {
        ready: () => {
          computeStampHeight()
        },
        select: (index) => {
          computeStampHeight()
          this.updateHeaderImage(index)
        },
      },
    })
  }

  updateHeaderImage(index) {
    requestAnimationFrame(() => {
      if (this.active) this.active.classList.remove('is-visible')
      this.active = this.headerImage.children[index]
      this.active.classList.add('is-visible')

      const logoClass =
        this.carousel.instances[0]?.selectedCell.element.dataset.theme

      if (this.logo && logoClass) {
        this.logo.classList.toggle('is-dark', logoClass == 'is-dark')
        this.logo.classList.toggle('is-light', logoClass == 'is-light')
      }
    })
  }
}

export const Home = new CHome()
