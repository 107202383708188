import Accordion from './components/accordion'
import Carousel from './components/carousel'
import Page from './components/page'
import LazyLoadEmbedBlocks from './components/lazy-load-embed'
import Navbar from './components/navbar'
import Sharer from './components/sharer'
import TopScroll from './components/topscroll'
import ConsentCookies from './components/consent-cookies'

import '../scss/base.scss'

class CAPP {
  constructor() {
    document.addEventListener('DOMContentLoaded', () => {
      console.log('[OAR base online]')

      this.navbar = new Navbar()
      this.sharer = new Sharer()
      this.topscroll = new TopScroll()
      this.carousel = new Carousel('.carousel.is-default', {
        wrapAround: true,
        pageDots: false,
        imagesLoaded: true,
        lazyLoad: 2,
        // fullscreen: true,
        arrowShape:
          'M 89.347 55.245 C 93.386 55.245 95.911 50.875 93.89 47.374 C 92.95 45.753 91.219 44.751 89.347 44.751 L 89.347 55.245 Z M 6.935 46.29 C 4.885 48.334 4.885 51.657 6.935 53.708 L 40.325 87.099 C 43.227 89.905 48.08 88.515 49.06 84.596 C 49.496 82.837 48.997 80.98 47.745 79.68 L 18.064 49.999 L 47.745 20.318 C 50.551 17.411 49.157 12.557 45.235 11.581 C 43.484 11.14 41.627 11.638 40.325 12.9 L 6.935 46.29 Z M 89.347 44.751 L 10.644 44.751 L 10.644 55.245 L 89.347 55.245 L 89.347 44.751 Z',
      })
      this.page = new Page()
      this.accordion = new Accordion()
      this.lazyLoadEmbed = new LazyLoadEmbedBlocks()

      if (this.page.menu)
        this.accordion.onToggle = () => {
          this.page.getScrollPositions()
        }
    })
  }
}

const APP = new CAPP()
export default APP

window.consentCookies = new ConsentCookies()
