export default class Sharer {
  constructor() {
    const btn = document.querySelector('#js-sharer')
    if (!btn) return

    const text = btn.querySelector('.text')
    const icons = btn.querySelectorAll('.icon')

    btn.addEventListener('click', () => {
      navigator.clipboard.writeText(window.location).then(() => {
        icons[0].remove()
        icons[1].classList.remove('is-hidden')
        text.innerHTML = 'Adresa a fost copiată'
      })
    })
  }
}
