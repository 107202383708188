import { ClickOutsideHandler } from '../handlers'

export default class Dropdown {
  constructor(element, trigger) {
    this.element = element
    this.trigger = element.querySelector('.dropdown-trigger')
    this.isSelect = element.classList.contains('is-filter')

    if (this.isSelect) {
      this.clearBtn = element.querySelector('.dropdown-clear')
      this.itemList = element.querySelector('.dropdown-content')
      this.counter = this.trigger.querySelector('.counter')
      this.onUpdate = null

      this.element.addEventListener('click', (e) => {
        if (e.target.type === 'checkbox') {
          this.updateFilters()
        } else if (e.target.classList.contains('js-reset')) {
          e.preventDefault()
          this.clearFilter()
        }
      })
    }

    this.trigger.addEventListener('click', (e) => {
      this.toggle()
    })

    if (this.clearBtn)
      this.clearBtn.addEventListener('click', (e) => {
        this.clearFilter()
      })

    ClickOutsideHandler.init()
  }

  toggle() {
    this.element.classList.toggle('is-active')
  }

  clearFilter() {
    this.checked.forEach((e) => {
      e.checked = false
    })

    this.updateFilters()
  }

  updateState() {
    this.checked = this.itemList.querySelectorAll(`input:checked`)
    this.element.classList.toggle('is-set', this.checked.length)
    this.clearBtn.classList.toggle('is-hidden', !this.checked.length)
    this.counter.innerHTML = this.checked.length
  }

  updateFilters() {
    if (this.isSelect) this.updateState()
    if (this.onUpdate) this.onUpdate()
  }
}
