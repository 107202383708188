import APP from '../base'
import throttle from 'lodash.throttle'
import Sizes from './sizes'

export default class Page {
  constructor() {
    this.article = document.querySelector('article')
    this.menu = document.querySelector('aside .menu')

    if (!Sizes.isMobile) {
      this.fixHeights()
    }

    // check all images have loaded before parsing scroll positions
    Promise.all(
      Array.from(document.images)
        .filter((img) => !img.complete)
        .map(
          (img) =>
            new Promise((resolve) => {
              img.onload = img.onerror = resolve
            })
        )
    ).then(() => {
      requestAnimationFrame(() => {
        this.initMenu()
      })
    })
  }

  fixHeights() {
    this.side = document.querySelector('.aside-container')

    if (this.side && this.article) {
      const hSide = this.side.offsetHeight
      const hArticle = this.article.offsetHeight

      if (hSide > hArticle) this.article.style.minHeight = hSide + 'px'
    }
  }

  getScrollPositions() {
    this.headingPositions = []
    this.carouselPositions = []
    const y = window.scrollY

    let bc = this.menu.getBoundingClientRect()
    this.menuPosition = [bc.top, bc.bottom]

    this.headings.forEach((e) => {
      this.headingPositions.push(e.getBoundingClientRect().top + y)
    })

    this.carousels.forEach((e) => {
      bc = e.getBoundingClientRect()
      this.carouselPositions.push([bc.top + y, bc.bottom + y])
    })
  }

  initMenu() {
    this.menuMobile = document.querySelector('#article-nav-mobile')

    if (!this.menu) return
    const links = this.menu.querySelectorAll('a')

    this.menu.addEventListener('click', (e) => {
      e.preventDefault()
      this.scrollToSection(e.target.dataset.id, 40)
    })

    this.menuMobile.addEventListener('change', () => {
      this.scrollToSection(this.menuMobile.value, 90)
    })

    window.addEventListener(
      'resize',
      throttle(() => {
        this.getScrollPositions()
      }, 100)
    )

    let active = null
    const checkScroll = () => {
      const y = window.scrollY

      // section highlight
      let index = this.headingPositions.findIndex((e) => e > y + 200)
      if (index == -1) index = links.length

      if (index > 0 && active != links[index - 1]) {
        if (active) active.classList.remove('is-active')
        active = links[index - 1]
        active.classList.add('is-active')
        this.menuMobile.value = active.dataset.id
      } else if (index == 0) {
        if (active) active.classList.remove('is-active')
        active = null
      }

      // menu toggle on carousel intersection
      if (!Sizes.isMobile) {
        if (this.carouselPositions.length) {
          index = this.carouselPositions.findIndex((e) => {
            return (
              e[0] < y + this.menuPosition[1] && y < e[1] + this.menuPosition[0]
            )
          })

          this.menu.classList.toggle('is-transparent', index != -1)
        }
      }
    }

    this.headings = this.article.querySelectorAll('.block-heading_block > h2')
    this.carousels = this.article.querySelectorAll('.block-carousel')

    this.getScrollPositions()

    // console.log('menuPosition', this.menuPosition)
    checkScroll()

    window.addEventListener('scroll', throttle(checkScroll, 100))
  }

  scrollToSection(id, offset = 0) {
    if (id) {
      const index = Array.from(this.headings).findIndex((e) => e.id == id)

      window.scrollTo({
        top: this.headingPositions[index] - offset,
        behavior: 'smooth',
      })
    }
  }
}
